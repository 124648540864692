import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Question from "../Components/Question";
import Response from "../Components/Reponse";
import "react-app-polyfill/ie11";

class IndexRoute extends Component {
  render() {
    return (
      <div className="IndexRoute">
        <Router>
          <div>
            <Route exact path="/:token" component={Response} />

            {/*

             <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />

             <Route exact path="/:token" component={Admin} />
            

              <Route exact path="/:token" component={Moderation} />
  
     <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />
            

  <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
         


 <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
  <Route exact path="/:token" component={Home} />
          
            <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Moderation} />
               <Route exact path="/:token" component={Admin} />

                <Route exact path="/:token" component={Moderation} />

       
            <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
           
            <Route exact path="/:token" component={Admin} />
             
            <Route exact path="/:token" component={Admin} />

             <Route exact path="/:token" component={Moderation} />

             
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/Chat" component={Chat} />
            <Route exact path="/Moderation" component={Moderation} />
            */}
          </div>
        </Router>
      </div>
    );
  }
}

export default IndexRoute;
