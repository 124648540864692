import React, { Component } from 'react';
import './Question.css';
import QCM from "./QuestionTypes/QCM"
import Points from "./QuestionTypes/Points"
import Cloud from "./QuestionTypes/Cloud"

import { Firebase_Config } from '../Config/config';
import * as firebase from 'firebase/app';
import 'firebase/database';

import PleniereData from '../Questions/Plénière'
import AtelierPsyData from '../Questions/Atelier-PSY'

const Firebase_Keys = {
    "pleniere": {
        CurrentQuestion: "DIC2023CurrentQuestion",
        displayQuestion: "NovartisDic"
    },
}

class Question extends Component {
    constructor(props, context) {
        super(props, context);

        if (!firebase.apps.length) {
            this.app = firebase.initializeApp(Firebase_Config);
        }
        this.state = {
            currentQuestion: -1,
            data: undefined,
            target: undefined,
            displayQuestion: false,
        }
    }

    componentWillMount() {
        this.db = firebase.database();

        // N° de la question
        this.db.ref(Firebase_Keys[this.props.match.params.token].CurrentQuestion).on('value', (snap) => {
            console.log(snap.val());
            this.setState({ currentQuestion: snap.val() });
        });
        this.db.ref(Firebase_Keys[this.props.match.params.token].displayQuestion).on('value', (snap) => {
            console.log(snap.val());
            this.setState({ displayQuestion: snap.val() > 0 ? true : true });
        });

        switch (this.props.match.params.token) {
            case "pleniere":
                this.setState({ data: PleniereData, target: this.props.match.params.token })
                break;
            /*case "sepcognition":
                this.setState({ data: AtelierSEPData, target: this.props.match.params.token })
                break;
            case "inertie":
                this.setState({ data: AtelierInertieData, target: this.props.match.params.token })
                break;*/
            case "atelierpsy":
                this.setState({ data: AtelierPsyData, target: this.props.match.params.token })
                break;
            default:
                break;
        }
    }

    questionTypeSwitch = () => {
        if (this.state.currentQuestion >= 0 && this.state.data) {
            switch (this.state.data[this.state.currentQuestion].type) {
                case "QCM":
                    console.log(this.state.data[this.state.currentQuestion])
                    return (
                        <div className='QBlock'>
                            <QCM key={"qcm" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                case "Points":
                    return (
                        <div className='PBlock'>
                            <Points key={"pts" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                case "Cloud":
                    return (
                        <div className='QBlock'>
                            <Cloud key={"cloud" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                default:
                    break;
            }
        }
    }

    render() {
        console.log(this.state.displayQuestion)
        return (
            <div style={{ position: "relative", height: window.innerHeight }}>
                {/*<div className='logoNovartis'>
                    <img style={{width:"100%"}} src={process.env.PUBLIC_URL + '/Images/NovartisLogo.png'} />
                </div>*/}
                {/*<div id="returnButton" className='cliquable boutonRetour' >
                    <a href='https://synapsesquiz.com/preprod/#/'>
                        <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + '/Images/arrowBack.png'} />
                    </a>
                </div>*/}
                {this.state.displayQuestion ? this.questionTypeSwitch() :
                    <div style={{ width: "100%", textAlign: "center", paddingTop: "24%", fontSize: 30 }}>
                        Patience, les questions s'afficheront le moment venu !
                    </div>}
            </div>
        )
    }
}

export default Question;