import React, { Component } from 'react';
import '../Reponse.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';

class QuestionPoints extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            points: null,
            mousePos: { x: 0, y: 0 },
            offsets: { x: 0, y: 0 },
            imgWidth: 0,
            imgHeight: 0,
            pointId: undefined,
            allPoints: [],
        }
        this.imgRef = React.createRef();
        this.divImgRef = React.createRef();
    }

    renderAllPoints = () => {
        if (this.state.allPoints) {
            var allPoints = [];

            this.state.allPoints.map((v, i) => {
                allPoints.push(
                    <div key={"points" + i} className='noselect' style={{
                        display: "flex", borderRadius: 50, border: "1px solid darkred", width: 30, height: 30, fontSize: 50, backgroundColor: "red", fontWeight: "bold",
                        position: "absolute", top: ((v.y * this.state.imgHeight) + this.state.offsets.y) - 15, left: ((v.x * this.state.imgWidth) + this.state.offsets.x) - 15, textAlign: "center", alignItems: "center", justifyContent: "center", fontFamily: "Arial"
                    }}>
                    </div>
                )
            })
            return (allPoints)
        }
    }

    getOffsetAndSizeImg = () => {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            if (offsetY !== this.state.offsets.y || offsetX !== this.state.offsets.x ||
                Width !== this.state.imgWidth || Height !== this.state.imgHeight) {
                this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
            }
        }
    }

    parseData = (raw) => {
        if (raw) {
            var newAllPoints = []
            raw.map((v) => {
                if (v.coordinates) {
                    for (let index = 0; index < v.coordinates.length; index++) {
                        const element = v.coordinates[index];
                        newAllPoints.push({
                            x: element.x,
                            y: element.y
                        })
                    }
                }
            })
            this.setState({ allPoints: newAllPoints })
        }
    }

    Update = () => {
        axios
            .post(API + "getPoints", {
                key: this.props.target,
                Qid: this.state.current,
                globalKey: globalKey
            })
            .then((res) => {
                console.log(res.data)
                this.parseData(res.data)
                //this.setState({ allPoints: res.data});
            })
    }

    HandleResize = () => {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
        }
    }

    componentDidMount() {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
        }
        this.Update()

        window.addEventListener('resize', this.HandleResize);
    }

    render() {
        this.getOffsetAndSizeImg();
        var imgStyle = { backgroundColor: "black", width: "100%" }
        if (this.imgRef && this.imgRef.current) {
            imgStyle.maxHeight = this.divImgRef.current.offsetHeight
            if (this.imgRef.current.naturalHeight > this.imgRef.current.naturalWidth) {
                imgStyle.width = undefined
            }
        }
        return (
            <div>
                <div className='TitlePoint'>
                    {this.state.data.title}
                </div>
                {this.renderAllPoints()}
                <div ref={this.divImgRef} className='imagePointsR' style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <img ref={this.imgRef} src={this.state.data.url ? process.env.PUBLIC_URL + this.state.data.url : ""} style={imgStyle} />
                </div>
            </div>
        )
    }
}

export default QuestionPoints;