import React, { Component } from 'react';
import '../Reponse.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';

const puces = ["A", "B", "C", "D", "E", "F", "G", "H", "I"]

class QuestionQCM extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            RawData: undefined,
            RawDataMultiple: undefined,
            r: [],
            rMultiple: [],
        }
    }

    parseData = () => {
        if (this.state.RawData && this.state.RawData.length > 0) {
            var newR = [];

            for (let index = 0; index < 10; index++) {
                newR.push({
                    c: 0,
                    p: 0
                })
            }
            for (let index = 0; index < this.state.data.r.length; index++) {
                this.state.RawData.map((v) => {
                    if (v.r[index]) {
                        const e = v.r[index];
                        if (e === "X") {
                            newR[index].c = newR[index].c + 1;
                        }
                    }
                })
                newR[index].p = ((newR[index].c * 100) / this.state.RawData.length);
            }

            console.log(newR)
            this.setState({ r: newR })
        }
    }

    parseDataMultiple = () => {
        if (this.state.RawDataMultiple && this.state.RawDataMultiple.length > 0) {
            var newR = [];

            for (let index = 0; index < 7; index++) {
                newR.push({
                    c: 0,
                    p: 0
                })
            }
            for (let index = 0; index < this.state.data.r.length; index++) {
                this.state.RawDataMultiple.map((v) => {
                    const e = v.r[index];
                    if (e === "X") {
                        newR[index].c = newR[index].c + 1;
                    }
                })
                newR[index].p = ((newR[index].c * 100) / this.state.RawDataMultiple.length);
            }

            console.log("newR:", newR)
            this.setState({ rMultiple: newR })
        }
    }

    Update = () => {
        axios
            .post(API + "getQCM", {
                key: this.props.target,
                Qid: this.state.current,
                globalKey: globalKey
            })
            .then((res) => {
                console.log(res.data)
                this.setState({ RawData: res.data });
                this.parseData();
            })
            .catch((err) => this.setState({ sended: false }));
    }

    getMultiple = (mul) => {
        axios
            .post(API + "getQCM", {
                key: this.props.target,
                Qid: mul,
                globalKey: globalKey
            })
            .then((res) => {
                console.log(res.data)
                this.setState({ RawDataMultiple: res.data });
                this.parseDataMultiple();
            })
            .catch((err) => this.setState({ sended: false }));
    }

    componentDidMount() {
        this.Update()
        if (this.props.data.multiple)
            this.getMultiple(this.props.data.multipleTarget)
    }

    componentDidUpdate() {
        console.log("r:", this.state.rMultiple)
    }

    render() {
        return (
            <div>
                <div className='TitleResponses'>{this.state.data.title}</div>
                {
                    this.props.data.multiple ? (
                        <div>
                            <div style={{ width: "50%", display: "inline-block" }}>
                                <div className='TitleSubResponses'>
                                    Anciens résultats
                                </div>
                                {
                                    this.state.data.r.map((v, index) => {
                                        const innerBarStyle = { width: (this.state.rMultiple[index] ? this.state.rMultiple[index].p : 0) + "%", height: 28 }
                                        return (
                                            <div key={index + "reponse"}>
                                                <div className='QCMBlock'>
                                                    <span className='QCMEntete'>{this.props.data.puces ? puces[index] : (index + 1)}. </span>
                                                    <span className='QCMReponse'>{v} <span style={{ float: "right" }}>({this.state.rMultiple[index] ? this.state.rMultiple[index].p.toFixed(2) : 0}%)</span></span>
                                                </div>
                                                <div className='QCMBlock progressbar' >
                                                    <div style={innerBarStyle}></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "50%", display: "inline-block" }}>
                                <div className='TitleSubResponses'>
                                    Nouveaux résultats
                                </div>
                                {
                                    this.state.data.r.map((v, index) => {
                                        const innerBarStyle = { width: (this.state.r[index] ? this.state.r[index].p : 0) + "%", height: 28 }
                                        return (
                                            <div key={index + "reponse"}>
                                                <div className='QCMBlock'>
                                                    <span className='QCMEntete'>{this.props.data.puces ? puces[index] : (index + 1)}. </span>
                                                    <span className='QCMReponse'>{v} <span style={{ float: "right" }}>({this.state.r[index] ? this.state.r[index].p.toFixed(2) : 0}%)</span></span>
                                                </div>
                                                <div className='QCMBlock progressbar' >
                                                    <div style={innerBarStyle}></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) :
                        this.state.data.r.map((v, index) => {
                            const innerBarStyle = { width: (this.state.r[index] ? this.state.r[index].p : 0) + "%", height: 28 }
                            return (
                                <div key={index + "reponse"}>
                                    <div className='QCMBlock'>
                                        <span className='QCMEntete'>{this.props.data.puces ? puces[index] : (index + 1)}. </span>
                                        <span className='QCMReponse'>{v} <span style={{ float: "right" }}>({this.state.r[index] ? this.state.r[index].p.toFixed(2) : 0}%)</span></span>
                                    </div>
                                    <div className='QCMBlock progressbar' >
                                        <div style={innerBarStyle}></div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
        )
    }
}

export default QuestionQCM;