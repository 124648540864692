import React, { Component } from 'react';
import './Reponse.css';
import QCM from "./ReponseTypes/QCM"
import Points from "./ReponseTypes/Points"
import Cloud from "./ReponseTypes/Cloud"

import { Firebase_Config } from '../Config/config';
import * as firebase from 'firebase/app';
import 'firebase/database';

import PleniereData from '../Questions/Plénière'
import AtelierPsyData from '../Questions/Atelier-PSY'

const Firebase_Keys = {
    "pleniere": {
        CurrentQuestion: "DIC2023CurrentQuestion",
        displayQuestion: "NovartisDic"
    },
}

class Response extends Component {
    constructor(props, context) {
        super(props, context);

        if (!firebase.apps.length) {
            this.app = firebase.initializeApp(Firebase_Config);
        }
        this.state = {
            currentQuestion: -1,
            data: undefined,
            target: undefined,
        }
        this.child = React.createRef();
    }

    componentWillMount() {
        this.db = firebase.database();

        // N° de la question
        this.db.ref(Firebase_Keys[this.props.match.params.token].CurrentQuestion).on('value', (snap) => {
            console.log(snap.val());
            this.setState({ currentQuestion: snap.val() });
        });

        switch (this.props.match.params.token) {
            case "pleniere":
                this.setState({ data: PleniereData, target: this.props.match.params.token })
                break;
            case "atelierpsy":
                this.setState({ data: AtelierPsyData, target: this.props.match.params.token })
                break;
            default:
                break;
        }
    }

    nextQuestion = () => {
        if (this.state.currentQuestion < this.state.data.length - 1) {
            firebase
                .database()
                .ref(Firebase_Keys[this.props.match.params.token].CurrentQuestion)
                .set(this.state.currentQuestion + 1)
        }
    }
    previousQuestion = () => {
        if (this.state.currentQuestion > 0) {
            firebase
                .database()
                .ref(Firebase_Keys[this.props.match.params.token].CurrentQuestion)
                .set(this.state.currentQuestion - 1)
        }
    }

    questionTypeSwitch = () => {
        if (this.state.currentQuestion >= 0 && this.state.data) {
            switch (this.state.data[this.state.currentQuestion].type) {
                case "QCM":
                    console.log(this.state.data[this.state.currentQuestion])
                    return (
                        <div className='QBlock'>
                            <QCM ref={this.child} key={"qcm" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                case "Points":
                    return (
                        <div className='PBlock'>
                            <Points ref={this.child} key={"pts" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                case "Cloud":
                    return (
                        <div className='QBlock'>
                            <Cloud ref={this.child} key={"cloud" + this.state.currentQuestion} current={this.state.currentQuestion} target={this.state.target} data={this.state.data[this.state.currentQuestion]} />
                        </div>
                    );
                default:
                    break;
            }
        }
    }

    RefreshData = () => {
        this.child.current.Update();
    }

    render() {
        return (
            <div style={{ position: "relative", height: window.innerHeight, overflowY:"hidden" }}>
                {/*<div className='logoNovartisResult'>
                <img style={{width:"100%"}} src={process.env.PUBLIC_URL + '/Images/NovartisLogo.png'} />
            </div>*/}
                {this.questionTypeSwitch()}
                <div style={{ position: "fixed", right: 15, bottom: 15, width: 90 }}>
                    <div onClick={() => this.previousQuestion()} className="button cliquable noselect" style={this.state.currentQuestion > 0 ? {} : { display: "none" }}>&lt;</div>
                    <div onClick={() => this.nextQuestion()} className="button cliquable noselect" style={this.state.currentQuestion < (this.state.data.length - 1) ? { float: "right" } : { float: "right", backgroundColor: "transparent", color: "transparent", border: "1px solid transparent" }}>&gt;</div>
                    <div onClick={() => this.RefreshData()} className="button cliquable noselect" style={{ color: "transparent", float: "right" }}>.</div>
                </div>
            </div>
        )
    }
}

export default Response;