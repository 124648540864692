import React, { Component } from 'react';
import '../Question.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';

class QuestionPoints extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            points: [],
            mousePos: { x: 0, y: 0 },
            offsets: { x: 0, y: 0 },
            imgWidth: 0,
            imgHeight: 0,
            pointId: undefined,
            divHeight: undefined,
        }
        this.imgRef = React.createRef();
        this.divImgRef = React.createRef();

        this.dropPoint = this.dropPoint.bind(this)
    }

    dropPoint = () => {
        var newPoints = this.state.points;
        var x = this.state.mousePos.x, y = this.state.mousePos.y, close = false;

        if (!newPoints) {
            newPoints = [{ x: (x - this.state.offsets.x) / this.state.imgWidth, y: (y - this.state.offsets.y) / this.state.imgHeight }];
            this.setState({ points: newPoints });
            this.sendResponse();
        }
        else {
            console.log(newPoints.length)
            newPoints.map((v) => {
                const vx = Math.abs(x - v.x), vy = Math.abs(y - v.y);
                if (vx <= 5 && vy <= 5) {
                    close = true;
                }
            })
            if (!close) {
                newPoints.push({ x: (x - this.state.offsets.x) / this.state.imgWidth, y: (y - this.state.offsets.y) / this.state.imgHeight });
                this.setState({ points: newPoints });
                this.sendResponse();
            }
        }
        console.log(newPoints)
    }

    handleMouseMove = (e) => {
        this.setState({ mousePos: { x: e.clientX + window.scrollX, y: e.clientY + window.scrollY }, offsets: { x: e.target.offsetLeft, y: e.target.offsetTop } });
    }

    renderAllPoints = () => {
        if (this.state.points) {
            var allPoints = [];

            this.state.points.map((v, i) => {
                allPoints.push(
                    <div onMouseMove={this.handleMouseMove} onClick={() => this.dropPoint()} key={"pointX" + i} className='noselect' style={{
                        display: "flex", width: 50, height: 50, fontSize: 50, color: "green", fontWeight: "bold",
                        position: "absolute", top: ((v.y * this.state.imgHeight) + this.state.offsets.y) - 25, left: ((v.x * this.state.imgWidth) + this.state.offsets.x) - 25, textAlign: "center", alignItems: "center", justifyContent: "center", fontFamily: "Arial"
                    }}>
                        X
                    </div>
                )
            })
            return (allPoints)
        }
    }

    deleteAllPoint = () => {
        if (this.state.points && this.state.points.length > 0) {
            var newPoints = this.state.points;
            newPoints.pop();
            this.setState({ points: newPoints })
            this.sendResponse();
        }
    }

    sendResponse = () => {
        /*var pointWOffsets = [];
        this.state.points.map((v) => {
            pointWOffsets.push({
                x: v.x - this.state.offsets.x,
                y: v.y - this.state.offsets.y,
            })
        })*/
        axios
            .post(API + "UpdatePoint", {
                points: this.state.points,
                pointId: this.state.pointId,
                globalKey: globalKey
            })
            .then((res) => {
                console.log("sended ", res.data)
            })
            .catch((err) => console.log(err));
    }

    getOffsetAndSizeImg = () => {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            if (offsetY !== this.state.offsets.y || offsetX !== this.state.offsets.x ||
                Width !== this.state.imgWidth || Height !== this.state.imgHeight) {
                this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
            }
        }
    }

    HandleResize = () => {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
        }
    }

    componentDidMount() {
        if (this.imgRef && this.imgRef.current) {
            const offsetY = this.imgRef.current.offsetTop, offsetX = this.imgRef.current.offsetLeft;
            const Width = this.imgRef.current.offsetWidth, Height = this.imgRef.current.offsetHeight;
            this.setState({ offsets: { x: offsetX, y: offsetY }, imgWidth: Width, imgHeight: Height });
        }

        axios
            .post(API + "NewPoint", {
                key: this.props.target,
                Qid: this.state.current,
                globalKey: globalKey
            })
            .then((res) => {
                this.setState({ pointId: res.data.v._id });
            })
            .catch((err) => { console.log(err) });

        window.addEventListener('resize', this.HandleResize);
    }

    render() {
        this.getOffsetAndSizeImg();
        var imgStyle = { backgroundColor: "black", width: "100%" }
        var divStyle = { marginLeft: "auto", marginRight: "auto" }
        if (this.imgRef && this.imgRef.current) {
            imgStyle.maxHeight = this.divImgRef.current.offsetHeight
            if (this.imgRef.current.naturalHeight > this.imgRef.current.naturalWidth) {
                imgStyle.width = undefined
            }
            console.log(window.innerHeight)
            if (window.innerHeight < 700) {
                divStyle.height = window.innerHeight * 0.85;
            }
        }
        return (
            <div>
                <div className='TitlePoint'>
                    {this.state.data.title}
                </div>
                {this.renderAllPoints()}
                <div ref={this.divImgRef} className='imagePointsR' style={divStyle}>
                    <img ref={this.imgRef} src={this.state.data.url ? process.env.PUBLIC_URL + this.state.data.url : ""} className='cliquable imagePoints inlineBlock' onMouseMove={this.handleMouseMove} onClick={() => this.dropPoint()} style={imgStyle} />
                </div>
                <div className='PointBoutonBlock' style={{ verticalAlign: "top" }}>
                    <div onClick={() => this.deleteAllPoint()} className="BoutonQCM cliquable noselect" style={{ width: "80%", paddingBottom: 10, marginTop: "10%" }}>
                        Supprimer la dernière croix
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionPoints;