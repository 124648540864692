import React, { Component } from 'react';
import '../Question.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';

const puces = ["A", "B", "C", "D", "E", "F", "G","H","I"]

class QuestionQCM extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            r: ["", "", "", "", "", "", ""],
            sended: false,
            accepted: false,
            error: "",
        }
    }

    clickOnAnswerQCM = (i) => {
        if (!this.state.sended) {
            var rCopy = this.state.r;
            if (this.state.r[i] === "X") {
                rCopy[i] = "";
            }
            else {
                rCopy[i] = "X";
            }
            this.setState({ r: rCopy })
        }
    }
    clickOnAnswer = (i) => {
        if (!this.state.sended) {
            var rCopy = ["", "", "", "", "", ""];
            if (this.state.r[i] === "X") {
                rCopy[i] = "";
            }
            else {
                rCopy[i] = "X";
            }
            this.setState({ r: rCopy })
        }
    }

    rVerify = () => {
        var isOk = false;
        this.state.r.map((v) => {
            if (v === "X") isOk = true;
        })
        return (isOk);
    }

    sendResponse = () => {
        if (this.rVerify()) {
            this.setState({ sended: true })
            axios
                .post(API + "QCM", {
                    key: this.props.target,
                    responses: this.state.r,
                    Qid: this.state.current,
                    globalKey: globalKey,
                })
                .then((res) => {
                    console.log("res :", res.status)
                    this.setState({ accepted: true, error: res.status });
                })
                .catch((err) => {
                    console.log("error :", err.response.status)
                    this.setState({ sended: false, error: err.response.status })
                });
        }
    }

    render() {
        const boutonClass = "BoutonQCM " + (this.state.sended ? "" : "cliquable");
        return (
            <div>
                <div className='Title'>{this.state.data.title}</div>
                {
                    this.state.data.r.map((v, index) => {
                        return (
                            <div key={index + "reponse"} className='QCMBlock'>
                                <div onClick={() => this.state.data.qcm ? this.clickOnAnswerQCM(index) : this.clickOnAnswer(index)} className='checkBox cliquable'><div style={{ marginTop: -7 }}>{this.state.r[index]}</div></div>
                                <span className='QCMEntete'>{this.state.data.puces?puces[index]:(index + 1)}. </span>
                                <span className='QCMReponse'>{v}</span>
                            </div>
                        )
                    })
                }
                {
                    this.state.accepted ?
                        <div>
                            <div className='BoutonText'>
                                Votre réponse a bien été prise en compte
                            </div>
                        </div> :
                        <div>
                            <div className='BoutonText'>
                                {this.state.data.qcm ? "Veuillez choisir votre/vos réponse(s) puis validez avec ce bouton:" : "Veuillez choisir votre réponse puis validez avec ce bouton:"}
                            </div>
                            <div onClick={() => this.sendResponse()} className={boutonClass} style={this.state.sended ? { backgroundColor: "gray" } : {}}>
                                Valider
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default QuestionQCM;