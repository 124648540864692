const Data = [
    {
        type: "QCM",
        title: "A propos de l’hépatite B et des troubles psychiatriques, quelle(s) est(sont) la(es) affirmation(s) exacte(s) ?",
        r: [
            "L’hépatite B est plus fréquente chez les patients vivant avec des troubles psychiatriques",
            "Les troubles psychiatriques sont plus fréquents chez les patients avec une hépatite B",
            "Les psychiatres sont tous barrés…",
            "Il y aura des quenelles sauce Nantua ce midi !",
        ],
        qcm: true
    },
    {
        type: "QCM",
        title: "Quel(s) antipsychotique(s) peut(vent) être à l’origine d’une insuffisance hépatique aiguë ?",
        r: [
            "La carbamazépine",
            "La clozapine",
            "La quétiapine",
            "Aucun de ces trois traitements",
        ],
        qcm: true 
    },
    {
        type: "QCM",
        title: "Quel(s) trouble(s) hépatiques est(sont) plus fréquent(s) chez les patients vivant avec une schizophrénie ?",
        r: [
            "Non Alcoholic Fatty Liver Desease",
            "Alcoholic Liver Disease",
            "Le syndrome LPAC (Low Phospholipid Associated Cholelithiasis)",
            "Aucun des trois",
        ],
        qcm: true 
    },
    //second PPT
    {
        type: "QCM",
        title: "Devant ces informations, que lui proposez-vous ?",
        r: [
            "Un prélèvement veineux obligatoire, il faut avoir une idée de la charge virale VHC",
            "Un Buvard pour la charge virale du VHC",
            "Un accompagnement global médico-psychosocial en CSAPA",
            "Un programme d’échange de seringues",
        ],
        qcm: true 
    },
    {
        type: "QCM",
        title: "Elle refuse toujours le traitement, que lui proposez-vous ?",
        r: [
            "Vous continuez de l’accompagner et vous lui proposez régulièrement le traitement",
            "Vous tentez de trouver un logement afin d’améliorer sa qualité de vie",
            "Vous refusez de continuer à la suivre si elle n’accepte pas le traitement",
            "Vous travaillez sur les autres facteurs hépatotoxiques"
        ],
        qcm: true 
    },
    {
        type: "QCM",
        title: "Vous abordez avec elle sa consommation d’alcool, de tabac, de cannabis et des autres substances psychoactives",
        r: [
            "Vous la rassurez sur sa consommation d’alcool d’environ 4 portions par jour en moyenne",
            "Vous passez rapidement sur son tabagisme qui n’est pas hépatotoxique",
            "Vous lui dites que sa consommation de cannabis à 3 joints par jour protège son foie",
            "Vous l’informez du risque hépatique lié à l’utilisation de MDMA"
        ],
        qcm: true 
    },
]

export default Data;