import React, { Component, createRef } from 'react';
import '../Reponse.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';
import WordCloud from 'wordcloud'

const baseWeight = 15;
const size = [1110, 705];
const testList = [["Henri8", 100], ["TheWhiteCoats", 50], ['StimApp', 30], ['Holiyou', 40], ["H8Live", 28], ["Webcast", 30], ["Studio", 18], ['Applications', 31], ['Séminaires', 10], ["Servier", 24], ["Novartis", 40], ["Gilead", 47], ['Lorem', 27], ['Ipsum', 20], ["Dolor", 17], ["sit", 70], ["Amet", 30], ['Consectetur', 32], ['Adipiscing', 29], ["Elit", 48], ["Sed", 26], ["Do eiusmod", 50], ['tempor', 30], ['Incididunt', 31],]

class QuestionQCM extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            RawData: undefined,
            RawDataMultiple: undefined,
            r: [],
        }
        this.refOuter = createRef();
        this.refInner = createRef();
    }

    Update = () => {
        axios
            .post(API + "getCloud", {
                key: this.props.target,
                Qid: this.state.current,
                globalKey: globalKey
            })
            .then((res) => {
                console.log(res.data)
                this.parseData(res.data);
            })
            .catch((err) => this.setState({ sended: false }));
    }

    parseData = (RawData) => {
        if (RawData) {
            console.log(RawData)
            var r = [];
            for (const [key, value] of Object.entries(RawData)) {
                r.push([value.word, baseWeight * value.nb]);
            }
            console.log(r)
            this.setState({ r: r })
        }
    }

    componentDidMount() {
        this.Update()
        if (this.state.data.img) {
            const img = new Image();
            img.src = this.state.data.img;
            img.onload = () => {
                this.refOuter.current.width = size[0]
                this.refOuter.current.height = size[1]
                var ctx = this.refOuter.current.getContext('2d')
                ctx.drawImage(img, 0, 0, size[0], size[1])
            }
        }
        if (this.state.data.cloud) {
            const cloud = this.state.data.cloud;
            this.refInner.current.width = cloud.width;
            this.refInner.current.height = cloud.height;
            var ctx = this.refInner.current.getContext('2d')
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, cloud.width, cloud.height);
        }
        this.generateWordCloud()
    }

    generateWordCloud = () => {
        WordCloud(this.refInner.current, {
            list: this.state.r,
            backgroundColor: "rgba(255,255,255,0)",
            drawOutOfBound: false,
            shrinkToFit: true,
            shuffle: false,
            color: (word, weight, fontSize, distance, theta) => {
                var changed = false;
                var selectedColor = "black";
                this.state.data.cloud.color.forEach(element => {
                    if (!changed && weight <= element.target) {
                        changed = true;
                        selectedColor = element.color
                    }
                })
                return selectedColor
            },
            rotateRatio: 0,
            rotationSteps: 2,
            gridSize: 16,
            fontFamily: ["Gotham", "sans-serif"]
        })
    }

    componentDidUpdate() {
        console.log("r:", this.state.r)
        this.generateWordCloud()
    }

    render() {
        return (
            <div>
                <div style={{ position: "relative", width: size[0], height: size[1], marginLeft: "auto", marginRight: "auto", marginTop: 100 }}>
                    <canvas style={{ zIndex: 1, position: "absolute" }} ref={this.refOuter}>
                    </canvas>
                    <canvas style={{ zIndex: 2, position: "absolute", left: this.state.data.cloud.offsetX, top: this.state.data.cloud.offsetY }} ref={this.refInner} />
                </div>
            </div>
        )
    }
}

export default QuestionQCM;