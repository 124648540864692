const Data = [
    {
        type:"Cloud",
        title:"Test cloud",
        img: "https://h8.events.novartis.fr/IMG/Nuage-de-mots-v2-cerveau.png",
        cloud: {
            offsetX: 230,
            offsetY: 100,
            width: 650,
            height: 500,
            color: [{color:"#f6ae2b", target: 25},{color:"#ec743c", target: 40},{color:"#dc422c", target: 60},{color:"#91201d", target: 200}]
        }
    },
    {
        type: "QCM",
        title: "Concernant les syndromes sclérodermiformes:",
        r: [
            "Il convient de demander un dosage du VEGF en cas de suspicion de syndrome POEMS.",
            "Des cas de syndromes sclérodermiformes au gadolinium sont toujours rapportés par la base de pharmacovigilance.",
            "Les sclérodermies systémiques et localisées (morphées) sont deux entités considérées comme bien distinctes.",
            "Le scléroedème de Buschke est une complication rare du diabète déséquilibré.",
            "L’infiltration du fascia à éosinophiles est un critère nécessaire pour confirmer une fasciite de Shulman."
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Concernant les syndromes sclérodermiformes:",
        r: [
            "Il convient de demander un dosage du VEGF en cas de suspicion de syndrome POEMS.",
            "Des cas de syndromes sclérodermiformes au gadolinium sont toujours rapportés par la base de pharmacovigilance.",
            "Les sclérodermies systémiques et localisées (morphées) sont deux entités considérées comme bien distinctes.",
            "Le scléroedème de Buschke est une complication rare du diabète déséquilibré.",
            "L’infiltration du fascia à éosinophiles est un critère nécessaire pour confirmer une fasciite de Shulman."
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quand doit-on débuter un traitement efficace du psoriasis si on veut modifier le cours de l’histoire naturelle du psoriasis ?",
        r: [
            "Immédiatement",
            "Dans l’année",
            "Dans les 2 ans",
            "Dans les 3 ans",
            "Après 5 ans"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quelles sont les options thérapeutiques ?",
        r: [
            "Stop ADA et reprendre l’IFX",
            "ADA + MTX",
            "ADA + Ciclosporine",
            "ADA + Photothérapie",
            "Ustekinumab",
            "Anti-IL23",
            "Anti-IL17"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quelles seraient les propositions thérapeutiques ?",
        r: [
            "Stop immunothérapie",
            "+ Acitrétine",
            "+ Methotrexate",
            "+ Adalimumab",
            "+ Ustekinumab",
            "+ Anti-IL23",
            "+ Photothérapie",
            "+ Anti-IL17",
            "+ Apremilast"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quelles seraient les propositions thérapeutiques ?",
        r: [
            "Soins locaux et chimio sans anti-PD1",
            "Pas de trt systémique pour le psoriasis",
            "+ Acitrétine",
            "+ Methotrexate",
            "+ Ustekinumab",
            "+ Anti-IL23",
            "+ Photothérapie",
            "+ Anti-IL17",
            "+ Apremilast"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Parmi les éléments suivants, lesquels vont vous orienter vers une maladie auto-inflammatoire ?",
        r: [
            "Une évolution par poussées",
            "La régression spontanée des symptômes",
            "La présence de séquelles entre les poussées",
            "La présence d’anticorps",
            "Un syndrome inflammatoire permanent",
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quelle est l’échelle la plus utile pour évaluer l’inflammation de l’hidradénite suppurée ?",
        r: [
            "HiSCR",
            "iHS4",
            "Hurley",
            "Sartorius"
        ],
        qcm: false,
        puces: true,
    },
    {
        type: "QCM",
        title: "Dans l’urticaire au froid:",
        r: [
            "Vous prescrivez en première intention de l’omalizumab.",
            "Vous prescrivez en première intention une seringue d’adrénaline.",
            "Vous réalisez un bilan diagnostique avec test au glaçon, NFS et CRP.",
            "Vous contre-indiquez pour tous les patients la baignade.",
            "Vous contre-indiquez pour tous les patients les sports extrêmes."
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Dans la dermatite atopique modérée à sévère:",
        r: [
            "3 biothérapies ont l’AMM chez l'adulte : le dupilumab, le tralokinumab et le nemolizumab.",
            "3 inhibiteurs de JAK ont l’AMM chez l’adulte : le baricitinib, l’upadacitinib et l’abrocitinib.",
            "Le dupilumab et le tralokinumab ont l’AMM et le remboursement chez l’adolescent à partir de 12 ans.",
            "Le dupilumab à l’AMM et le remboursement chez l’enfant dès 6 ans.",
            "Le remibrutinib, un inhibiteur sélectif de la tyrosine kinase de Bruton, est une option thérapeutique potentielle."
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Hypothèses ?",
        r: [
            "Nécrolyse épidermique toxique post médicamenteuse?",
            "Pemphigus induit par l’immunothérapie?",
            "Pemphigoïde cicatricielle induite par l’immunothérapie?",
            "Lupus bulleux NET like induit par l’immunothérapie?",
            "Pemphigus paranéoplasique?",
            "Lupus paranéoplasique?"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Hypothèses ?",
        r: [
            "Nécrolyse épidermique toxique post médicamenteuse?",
            "Pemphigus induit par l’immunothérapie?",
            "Pemphigoïde cicatricielle induite par l’immunothérapie?",
            "Lupus bulleux NET like induit par l’immunothérapie?",
            "Pemphigus paranéoplasique?",
            "Lupus paranéoplasique?"
        ],
        qcm: true,
        puces: true,
    },
    {
        type: "QCM",
        title: "Quelle serait votre principale hypothèse diagnostique ?",
        r: [
            "Une toxidermie",
            "Un syndrome toxinique",
            "Une éruption virale",
            "Une pseudo acrodermatite entéropathique",
        ],
        qcm: true,
        puces: true,
    },
]

export default Data;