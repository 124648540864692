import React, { Component } from 'react';
import '../Question.css';
import axios from "axios"
import { API, globalKey } from '../../Config/config';

class QuestionQCM extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            data: props.data,
            current: props.current,
            r: [],
            sended: false,
            accepted: false,
            word: "",
            error: "",
        }
    }

    clickOnWord = () => {
        if (!this.state.sended) {
            this.setState({ r: [this.state.word.toUpperCase()] }, () => {
                this.sendResponse();
            })
        }
    }
    clickOnMultipleWords = () => {
        if (!this.state.sended) {
            var rCopy = this.state.r;
            if (!rCopy.includes(this.state.word)) {
                rCopy.push(this.state.word)
            }
            this.setState({ r: rCopy })
        }
    }

    sendResponse = () => {
        if (this.state.r.length > 0) {
            console.log(this.state.r)
            axios
                .post(API + "Cloud", {
                    key: this.props.target,
                    responses: this.state.r,
                    Qid: this.state.current,
                    globalKey: globalKey,
                })
                .then((res) => {
                    console.log("res :", res.status)
                    this.setState({ accepted: true, error: res.status });
                })
                .catch((err) => {
                    console.log("error :", err)
                    this.setState({ sended: false, error: err })
                });
        }
    }

    render() {
        const boutonClass = "BoutonQCM " + (this.state.sended ? "" : "cliquable");
        return (
            <div>
                <div className='Title'>{this.state.data.title}</div>
                {
                    <div className='cloudAskBox cloudAskBoxMulti'>
                        <textarea value={this.state.word} onChange={(v) => {this.setState({word:v.target.value})}} style={{width:"100%",height:80,fontSize:20}} placeholder="Votre mot ici..."/>
                    </div>
                }
                {
                    this.state.accepted ?
                        <div>
                            <div className='BoutonText'>
                                Votre mot a bien été prise en compte
                            </div>
                        </div> :
                        <div>
                            <div className='BoutonText'>
                                {"Veuillez écrire un mot puis validez avec ce bouton:"}
                            </div>
                            <div onClick={() => this.clickOnWord()} className={boutonClass} style={this.state.sended ? { backgroundColor: "gray" } : {}}>
                                Envoyer
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default QuestionQCM;